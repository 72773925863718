import { instance } from "../backend";
import { CONST } from "./constants";


export function verifyPassword(value: any) {
  const strongRegex = new RegExp(
    '^(?=.*[a-z,ä,ö,ü])(?=.*[A-Z,Ä,Ö,Ü])(?=.*[0-9])(?=.*[!@_#?.$%^&*/\\\\])(?=.{8,})'
  );
  return strongRegex.test(value);
}

export function verifyPhone(value: any) {
  const strongRegex = /^[0-9()+-\/]+$/;
  return strongRegex.test(value);
}

export function decimal(value: any, args: any) {
  let count = 1;
  if (args != undefined && args.length != 0 && parseInt(args[0])) {
    count = +args[0];
  }
  const strongRegex = new RegExp(`^[0-9]+(\.[0-9]{1,${count}})?$`);
  return strongRegex.test(value);
}

export function verifyAlreadyExistUsername(value: any, args: any) {
  const editingUserId =
    args == undefined || args[0] == '' || args[0] == 'undefined'
      ? CONST.emptyGuid
      : args[0];

  const isForCustomer = args != undefined && args[1] == 'forCustomer';
  let url = '/api/ApplicationUsers/ValidateUserName';
  if (isForCustomer) {
    url = '/api/ApplicationUsers/ValidateUserNameForContact';
  }
  return instance
    .post<Promise<void>>(url, {
      username: value,
      id: editingUserId,
    })
    .then((response: any) => true)
    .catch((e) => {
      console.log(e.response.data);
      return false;
    });
}

export function verifyUsernameCharacters(value: any, args: any) {
  if (
    typeof value == 'string' &&
    /^[-._@+abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789äöüÄÖÜßáéíñóúÁÉÍÑÓÚàâæèêëîïôœùûŒÇÀÂÆÈÊËÎÏÔÙÛìòÌÒåøÅØ]{1,}$/.test(
      value as string
    )
  ) {
    return true;
  }
  return false;
}

export async function verifyAlreadyExistEmail(value: any, args: any) {
  const editingUserId =
    args == undefined || args[0] == "" || args[0] == "undefined"
      ? CONST.emptyGuid
      : args[0];
  const companyId =
    args == undefined || args[1] == "" || args[1] == "undefined"
      ? CONST.emptyGuid
      : args[1];
  const url = '/api/ApplicationUsers/ValidateExistingEmail';
  try {
    const response = await instance.post<Promise<void>>(url, {
      email: value,
      id: editingUserId,
      companyId: companyId,
    });
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function verifyAlreadyExistContactInternalEmail(value: any, args: any) {
  const editingUserId =
    args == undefined && args[0] == '' ? CONST.emptyGuid : args[0];
  const url = '/api/ApplicationUsers/ValidateExistingContactInternalEmail';
  try {
    const response = await instance.post<Promise<void>>(url, {
      email: value,
      id: editingUserId,
    });
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function verifyAlreadyExistMembernumber(value: any, args: any) {
  if (!value) return true;
  const attendeeId = args[1] ?? CONST.emptyGuid;
  const url = `/api/Attendees/IsUniqueMemberNumber/${value}${
    attendeeId ? '?attendeeId=' + attendeeId : ''
  }`;
  try {
    const res = await instance.get<boolean>(url);
    return res.data;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function verifyAlreadyExistCompanyName(value: any, args: any) {
  const editingCompanyId = args == undefined || args[0] == '' ? CONST.emptyGuid : args[0];
  return instance
    .post<Promise<void>>('/api/Company/ValidateCompanyName', {
      name: value,
      id: editingCompanyId,
    })
    .then((response: any) => true)
    .catch((e) => {
      console.log(e.response.data);
      return false;
    });
}

export function verifyHours(value: any, args: any) {
  // sometimes "value" is type of number and .replace() function - does not working.
  // so, forcibly cast to string
  value = value.toString();
  const locale = args[0];
  if (value == '0') {
    return true;
  }
  if (locale == 'de') {
    value = value.replace(',', '.');
  }
  const floatValue = parseFloat(value);
  if (floatValue > 24) {
    return false;
  }
  // format: 0 or 10 or 24
  if (/^\d\d?$/.test(value)) return true;
  //format: (0.5 or 10.5 or 10.50)
  if (locale == 'en') {
    return /^(([0-9])|(1[0-9])|2[0-4])\.[0-9][0-9]?$/.test(value);
  } else {
    return /^(([0-9])|(1[0-9])|2[0-4])\.[0-9][0-9]?$/.test(value);
  }
}

export function url(str: any) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  );
  return !!pattern.test(str);
}

export function verifyDateFormat(value: any, args: any) {
  const locale = args[0];
  if (locale == 'en') {
    return /^([0-9]{4}|[0-9]{2})[\/]([0]?[1-9]|[1][0-2])[\/]([0]?[1-9]|[1|2][0-9]|[3][0|1])$/.test(
      value
    ); //yyyy/MM/dd
  } else {
    return /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[.]([0]?[1-9]|[1][0-2])[.]([0-9]{4}|[0-9]{2})$/.test(
      value
    ); //dd.MM.yyyy
  }
}

export async function verifyValidIban(value: any, args: any) {
  const optional = args?.length ? args[0] : undefined;
  if (optional && !value) {
    return true;
  }

  const url = '/api/PaymentData/ValidateIban';
  try {
    const response = await instance.post<Promise<void>>(url, { iban: value });
    return true;
  } catch (e) {
    return false;
  }
}

export function verifyPastDate(value: any, args: any) {
  const today = new Date();
  today.setUTCHours(0);
  today.setUTCMinutes(0);
  today.setUTCSeconds(0);
  today.setUTCMilliseconds(0);

  const passedDate = new Date(value);
  return passedDate <= today;
}

export function verifyFutureDate(value: any, args: any) {
  const today = new Date();
  today.setUTCHours(0);
  today.setUTCMinutes(0);
  today.setUTCSeconds(0);
  today.setUTCMilliseconds(0);

  const passedDate = new Date(value);
  return passedDate >= today;
}
